import { Center, Stack } from "@chakra-ui/react"
import {motion} from 'framer-motion';
const Loading=()=>{
    return <div className="container" style={{height:"100vh"}}>
        <Center height="100%">
            <Stack >
                <motion.div animate={{ scale:[1,1.2,1],opacity:[0.7,1,0.7] }} transition={{ duration: 0.7,repeat: Infinity,repeatDelay:0.5 }} >
                <img src="./assets/full_logo.png"  style={{maxHeight:"150px"}} alt="bms_ieee_logo"></img>
                <Center>
                <p>Loading....</p>
                </Center>
                </motion.div>
                
             </Stack>
        </Center>

    </div>
}
export default Loading