import { useDisclosure } from "@chakra-ui/hooks"
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    IconButton,
    Box,
    useColorModeValue,
    useColorMode,
    Button,
  } from "@chakra-ui/react"

import React  from "react"
import { HamburgerIcon, MoonIcon, SunIcon } from "@chakra-ui/icons"
import { withRouter } from "react-router"



const NavBar=({mobile})=>{


    const bg=useColorModeValue("#F6F6F6","#1A202C")
    const ic=useColorModeValue(<MoonIcon></MoonIcon>,<SunIcon></SunIcon>)
    const { toggleColorMode } = useColorMode()

      return (<>

         <Box shadow="base" style={{width:"100%",height:"60px",padding:"10px",backgroundColor:bg,borderRadius:"0px 0px 1rem 1rem",}}
         className="navbar fixed-top">
            <div className="container">
                <div className="row " style={{width:"100%"}}>
                    <div className="col-auto animated fadeInDown">
                        <img src="./assets/full_logo.png"  style={{maxHeight:"50px"}} alt="bms_ieee_logo"></img>

                    </div>
                    <div className="col-auto  animated fadeInDown" style={{marginLeft:"auto",marginTop:"3px"}}>
                      <IconButton variant="ghost" icon={ic} onClick={toggleColorMode}></IconButton>
                    </div>
                    {
                      mobile.matches?<div className="col-auto d-md-none " >
                      <DrawerExample></DrawerExample>
                    </div>:<div className="col-auto d-none d-md-block  animated fadeInDown" >
                       <div className="row">
                           <Navs ></Navs>
                       </div>
                    </div>
                    }
                    
                </div>
            </div>
         </Box>
      </>)
  }

export default NavBar

const NavsWithoutRouter=(props)=>{
    const sel=useColorModeValue("cyan.900","teal")

    const l=props.location.pathname.split('/')[1]
    const move=(loc)=>{
      if(props.onClose)
      {
        props.onClose();
        setTimeout(()=>{
          props.history.push(loc)
        },100)
      }
      else{
        props.history.push(loc)
      }


    }
    return <>

      <div className="col-auto nav-item" style={{marginTop:"10px"}} >
        <Button variant="link"
                colorScheme={l==="home"?sel:"gray"}
                onClick={()=>move("/home")}>Home</Button>
        </div>
        <div className="col-auto nav-item" 
        
        style={{marginTop:"10px"}}>
        <Button variant="link"  colorScheme={l==="events"?sel:"gray"}
        onClick={()=>move("/events")}>Events</Button>

      </div>
      <div className="col-auto nav-item" style={{marginTop:"10px"}}>
        <Button variant="link"
          colorScheme={l==="teams"?sel:"gray"}
          onClick={()=>move("/teams")}>Meet the Team</Button>
      </div>
      <div className="col-auto nav-item" style={{marginTop:"10px"}}>
        <Button variant="link" 
                colorScheme={l==="chapters"?sel:"gray"}
                onClick={()=>move("/chapters")}>Chapters</Button>
            
      </div>
      <div className="col-auto nav-item" style={{marginTop:"10px"}}>
        <Button variant="link"
                colorScheme={l==="collabs"?sel:"gray"}
                onClick={()=>move("/collabs")}>Collabs</Button>

      </div>
      <div className="col-auto nav-item" style={{marginTop:"10px"}}>
        <Button variant="link" 
                colorScheme={l==="joinus"?sel:"gray"}
                onClick={()=>move("/joinus")}>Join us</Button>
            
      </div>
    </>
}

const Navs=withRouter(NavsWithoutRouter)


function DrawerExample() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    return (
      <>
            <IconButton variant="ghost"
            icon={<HamburgerIcon color={useColorModeValue("black","white")} w={6} h={6}></HamburgerIcon>} onClick={onOpen}></IconButton>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>BMSCE IEEE</DrawerHeader>
              <DrawerBody>
                <Navs onClose={onClose}></Navs>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </>
    )
  }


