import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from "./theme"
import { ChakraProvider,ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigureStore } from './redux/configurestore';
import { Provider } from 'react-redux';
import "aos/dist/aos.css"


const store=ConfigureStore()
ReactDOM.render(
  <React.StrictMode>
  <ChakraProvider theme={theme}> 
    <ColorModeScript  initialColorMode={theme.config.initialColorMode} />
    <Provider store={store}>
    <BrowserRouter>
    <App />
    </BrowserRouter>
    </Provider>
  </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//chakraProvider provides chakra functionalities
//ColorMode Script gives dark n light mode functionalities
