import * as ActionTypes from './ActionTypes';
  
  export const Events=(state={
      isLoading: true,
      errMess: null,
      events: null,
      upcoming:null,
  }, action)=>{
      switch(action.type){
          case ActionTypes.GET_EVENTS:
              return {...state, isLoading:false, errMess: null, events:action.events, upcoming:action.upcoming};

            
          case ActionTypes.EVENTS_FAILED:
              return {...state, isLoading:false, errMess: action.payload, events:null};
  
          case ActionTypes.EVENTS_LOADING:
              return {...state, isLoading:true, errMess: null, events:null};
          default:
              return state;
      }
  }