import { useColorModeValue } from "@chakra-ui/color-mode"
import { Box, Link } from "@chakra-ui/layout"
import { SocialIcon } from 'react-social-icons';
import shortid from "shortid";

const Footer=()=>{
    const headText=useColorModeValue("#1A202C","#CBD5E0")
    const normText=useColorModeValue("#2D3748","#A0AEC0")
    const bg=useColorModeValue("#F6F6F6","#1A202C")
    const socials=["https://instagram.com/bmsce_ieee?utm_medium=copy_link","https://www.linkedin.com/company/bmsce-ieee",
    "http://www.discord.gg/F7pdPMenSU","https://youtube.com/channel/UC-WkHrFCtkufgt_e6ztZ1Qg"]
    return <>
    <div style={{width:"100%",backgroundColor:bg,paddingTop:"40px",paddingBottom:"20px"}}>
        <div className="container" style={{color:normText}}>
            <div className="row justify-content-center">
                
            <div className="col-12 col-md-6"  style={{textAlign:"center",marginTop:"20px"}}>
            <p className="h4" style={{color:headText}}>Follow us on</p> 
            {
                socials.map((url)=><SocialIcon
                target="_blank"
                key={shortid.generate()} url={url}  style={{ height: 40, width: 40,margin:"10px" }}/>)
            }
            </div>
                <div className="col-12 col-md-6"  style={{textAlign:"center",marginTop:"20px"}}>
                <p className="h4" style={{color:headText}}>Contact us</p> 
                    
                <div > 
              <i className="fa fa-user"></i> <a href="mailto:varun.ei21@bmsce.ac.in">Varun Rao HB</a>
              <br/>
              <Link href="mailto:bms.ieeesb@gmail.com" target="blank" >
                <i className="fa fa-envelope"></i> bms.ieeesb@gmail.com <br/>
              </Link>
<i className="fa fa-map-marker"></i> P.O. Box No.: 1908, Bull Temple
              Road,<br/> Bangalore - 560 019
            </div>
                </div>
            
            </div>
        </div>
        <br/><br/>
        <Box  padding={1}>
        <div className='container'>
        <div className="row justify-content-center">
            <p className="col-auto" style={{fontSize:"15px",color:normText}}>Useful links : </p>
        <Link className="col-auto" href="./assets/bylaws.pdf"><p style={{fontSize:"15px",color:normText}}>By laws</p></Link>
        <Link className="col-auto" href="https://www.ieee.org/"><p style={{fontSize:"15px",color:normText}}>IEEE</p></Link>
        <Link className="col-auto" href="https://sites.ieee.org/indiacouncil/"><p style={{fontSize:"15px",color:normText}}>Region 10</p></Link>
        <Link className="col-auto" href="https://ieeebangalore.org/"><p style={{fontSize:"15px",color:normText}}>Bangalore Section</p></Link>
        <Link className="col-auto" href="https://ieee-collabratec.ieee.org/"><p style={{fontSize:"15px",color:normText}}>IEEE Collabratec</p></Link>
        <Link className="col-auto" href="https://sites.ieee.org/indiacouncil/"><p style={{fontSize:"15px",color:normText}}>About Global IEEE Membership</p></Link>
        <Link className="col-auto" href="https://www.bmsce.ac.in/"><p style={{fontSize:"15px",color:normText}}>BMS College of Engineering</p></Link>
        </div>
        </div>
        </Box>
        <Box padding={1}>
        <div className='container' style={{textAlign:"center",fontSize:"15px",fontWeight:"500",color:normText}}>
        &#169; All rights reserved BMSCE IEEE
        </div>
        </Box>
    </div>
    </>
}
export default Footer

/*
const Useful=<div className="col-12 col-md-6" style={{textAlign:"center",marginTop:"20px"}}>
<p className="h6" style={{color:headText, textDecoration: "bolder"}}>USEFUL LINKS</p> 


</div>
*/