export const COLLABS_LOADING='COLLABS_LOADING';
export const COLLABS_FAILED='COLLABS_FAILED';
export const GET_COLLABS='GET_COLLABS';

export const EVENTS_LOADING='EVENTS_LOADING';
export const EVENTS_FAILED='EVENTS_FAILED';
export const GET_EVENTS='GET_EVENTS';



