import './App.css';
import NavBar from './Navbar';
import { useColorModeValue } from '@chakra-ui/color-mode';
import Footer from './Footer';
import { Redirect, Route, Switch } from 'react-router';
import ScrollToTop from './ScrollToTop';
import React,{Suspense, useEffect, useState} from 'react';
import Loading from './Loading';

//lazy load components
const Home=React.lazy(()=>import("./Home"));
const Events=React.lazy(()=>import("./Events"));
const About=React.lazy(()=>import("./About"));
const Chapters=React.lazy(()=>import("./Chapters"));
const Collabs=React.lazy(()=>import("./Collabs"));
const JoinUs=React.lazy(()=>import("./JoinUs"));
const Teams=React.lazy(()=>import("./Teams"));



const withSuspense=(el)=>{
    return <Suspense fallback={<Loading></Loading>}>
        {el}
    </Suspense>
}


function App() {
  const [mobile, setMobile] = useState({
    matches: window.innerWidth > 768 ? false : true,
  });

useEffect(()=>{
    let mediaQuery = window.matchMedia("(min-width: 768px)");
        mediaQuery.addEventListener('change', () => {
            setMobile(mediaQuery.matches);
        });
    return ()=>{
       mediaQuery.removeEventListener('change', () => {
            setMobile(mediaQuery.matches);
        });
    }
},[]);



  const back = useColorModeValue("#EEEEEE", "#171923")
  const col = useColorModeValue("#171923", "whitesmoke")
  return (
    <div className="App"  style={{backgroundColor:back,color:col,minHeight:"100vh"}}>
      <NavBar mobile={mobile}></NavBar>
      <ScrollToTop />
      <div style={{height:"60px"}}></div>
      <Switch>
        <Route exact path="/home" component={()=>withSuspense(<Home mobile={mobile}></Home>)}></Route>
        <Route exact path="/events" component={()=>withSuspense(<Events></Events>)}></Route>
        <Route exact path="/about" component={()=>withSuspense(<About></About>)}></Route>
        <Route exact path="/chapters" component={()=>withSuspense(<Chapters></Chapters>)}></Route>
        <Route exact path="/teams" component={()=>withSuspense(<Teams></Teams>)}></Route>
        <Route exact path="/collabs" component={()=>withSuspense(<Collabs></Collabs>)}></Route>
        <Route exact path="/chapters" component={()=>withSuspense(<Chapters></Chapters>)}></Route>
        <Route exact path="/joinus" component={()=>withSuspense(<JoinUs></JoinUs>)}></Route>

        <Redirect to="/home"></Redirect>
      </Switch>
     <Footer></Footer>
    </div>
  );
}

export default App;
