import * as ActionTypes from './ActionTypes';
  
  export const Collabs=(state={
      isLoading: true,
      errMess: null,
      collabs: null,
  }, action)=>{
      switch(action.type){
          case ActionTypes.GET_COLLABS:
              return {...state, isLoading:false, errMess: null, collabs:action.collabs};
          case ActionTypes.COLLABS_FAILED:
              return {...state, isLoading:false, errMess: action.payload,collabs:null};
  
          case ActionTypes.COLLABS_LOADING:
              return {...state, isLoading:true, errMess: null,collabs:null};
          default:
              return state;
      }
  }