
import {createStore , combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {logger} from 'redux-logger';
import {Events} from './Events';
import {Collabs} from './Collabs';
export const ConfigureStore=()=>{
      const store= createStore(
       combineReducers({
        events:Events,
        collabs:Collabs
       }),
       applyMiddleware(thunk)
      );
      return store;
    }

